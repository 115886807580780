<template>
	<div class="box">
		<div class="left">
			<div class="total-revenue">
				<div class="title">{{ $t('accountbalance') }}</div>
				<div class="num">{{ myInfo.wallet }}</div>
			</div>
<!--      <div class="total-revenue">-->
<!--        <div class="title">{{ $t('myintegral') }}</div>-->
<!--        <div class="num">{{ myInfo.integral }}</div>-->
<!--      </div>-->
			<div class="total-revenue" v-if="$store.state.identity != 3">
				<div class="title">{{ $t('Todayexpend') }}</div>
				<div class="num">{{ myInfo.purchase_count }}</div>
			</div>
			<div class="total-revenue">
				<div class="title">{{ $t('withdrawalamount') }}</div>
				<div class="num">{{ myInfo.withdrawal }}</div>
			</div>
		</div>
		<div class="right">
			<div class="top">
<!--        <div @click="integralDetail" class="detail">{{ $t('integralDetail') }} · </div>-->
				<div v-if="$store.state.identity != 3" @click="chargeDetail" class="detail">{{ $t('topupdetails') }}</div>
				<div v-if="$store.state.identity == 3" @click="chargeDetail" class="detail">{{ $t('revenueDetail') }}</div>
				<Icon name="icon-7key-musicround-keyboard_arrow-2" color="#fff" width="10" height="10">
				</Icon>
			</div>
			<div class="bottom">
<!--        <div @click="exchangePop()" class="recharge">{{ $t('exchangeintegral') }}</div>-->
        <div @click="recharge(1)" class="recharge">
          {{ $t('recharge') }}
        </div>
				<div v-if="$store.state.identity == 3" @click="recharge(2)" class="withdrawal">
					{{ $t('withdraw') }}
				</div>
        <!--<div v-if="$store.state.identity != 3" @click="recharge(1)" class="recharge">{{ $t('recharge') }}</div>-->
			</div>
		</div>

    <!-- 兑换积分 弹窗 -->
    <PopupCommon ref="popupExchange" customClassName="textarea-modal" title="" :visible.sync="visible">
      <div :slot="'title'" class="pop-title">{{ $t('exchangeintegral') }}</div>
      <div :slot="'content'" class="pop-content">
        <el-alert title="1元兑换10积分" type="warning" :closable="false" style="margin-bottom: 20px"></el-alert>
        <el-form :model="exForm" :rules="exrules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item :label="$t('exchangemoney')" prop="exmoney">
            <el-input v-model="exForm.exmoney"  type="number" @input="inputduihuan"></el-input>
          </el-form-item>
          <el-form-item :label="$t('myintegral')" prop="">
            <div>{{exintegral}}</div>
          </el-form-item>
        </el-form>
      </div>
      <div :slot="'footer'" class="pop-footer">
        <div class="confirm" @click="finishduihuanjifen">{{$t('exchangeintegral')}}</div>
      </div>
    </PopupCommon>


		<!-- 充值弹窗 -->
		<PopupCommon ref="popupRecharge" customClassName="textarea-modal" title=" " :visible.sync="visible">
			<div :slot="'title'" class="pop-title">{{ popTitle }}</div>
			<div :slot="'content'" class="pop-content">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
					<el-form-item :label="moneyLabel" prop="money">
						<el-input v-model="ruleForm.money" :placeholder="moneyPlaceholder" type="number" @input="inputaibian"></el-input>
					</el-form-item>
				</el-form>
        <pay-immediately-bottom :orderInfo="payInfo" @wechatPay="wechatPay" @alipayPay="alipayPay"></pay-immediately-bottom>
      </div>
			<div :slot="'footer'" class="pop-footer">
        <div class="confirm" @click="demoFinishPay">{{$t('recommend.completePayment')}}</div>
			</div>
		</PopupCommon>

    <!-- 提现弹窗 -->
    <PopupCommon ref="popupWithdraw" customClassName="textarea-modal" title=" " :visible.sync="visible">
      <div :slot="'title'" class="pop-title">{{ popTitle }}</div>
      <!-- <div style="width:556px;height:1px;background: ;"></div> -->
      <div :slot="'content'" class="pop-content">
        <!-- {{$t('recommend.loginSucceededContent')}} -->
        <el-form :model="tixianruleForm" :rules="txrules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item :label="$t('bankcard')" prop="bankCard">
            <!-- <el-select v-model="ruleForm.bankCard" placeholder="请选择银行卡">
              <el-option label="中国工商银行" value="shanghai"></el-option>
              <el-option label="中国农业银行" value="beijing"></el-option>
            </el-select> -->
            <el-select @change="selectChange" v-model="tixianruleForm.bankId" :placeholder="$t('changebankcard')" popper-class="reset-popper-bg-color">
							<span v-for="(item1, index) in $store.state.bankList" :key="index">
								<el-option :label="item1.bank_card" :value="item1.bank_card"></el-option>
							</span>
            </el-select>
          </el-form-item>
          <el-form-item :label="moneyLabel" prop="money">
            <el-input v-model="tixianruleForm.money" :placeholder="moneyPlaceholder" type="number"></el-input>
            <div v-if="type == 2" class="money-desc">{{$t('withdrawalamount')}}{{ myInfo.withdrawal }}</div>
          </el-form-item>
        </el-form>
      </div>
      <div :slot="'footer'" class="pop-footer">
          <div class="cancel" @click="cancelRecharge">{{ $t('recommend.cancel') }}</div>
          <div class="confirm" @click="confirmRecharge">{{ $t('recommend.confirm') }}</div>
      </div>
    </PopupCommon>

		<!-- 充值 提现成功弹窗 -->
		<PopupCommon ref="popupSubmit" customClassName="textarea-modal" title=" " :visible.sync="visible1"
			:width="'556px'">
			<div :slot="'title'" class="pop-title">{{$t('success')}}</div>
			<!-- <div style="width:556px;height:1px;background: ;"></div> -->
			<div :slot="'content'" class="pop-content-submit">
				<Icon name="icon-7key-musicguanzhuchenggong" color="#00BEA5" width="45" height="45" />
				<div class="title">{{ popTitle }}{{$t('success')}}</div>
				<!-- <div class="desc">我们会尽快为您安排音乐人完成您的需求</div> -->
			</div>
			<div :slot="'footer'" class="pop-footer-submit">
				<div class="cancel" @click="closePop">{{$t('close')}}</div>
				<!-- <div class="confirm" @click="confirm">确认</div> -->
			</div>
		</PopupCommon>

		<!-- 充值明细弹窗 -->
		<PopupCommon ref="popupChargeDetail" customClassName="textarea-modal" title=" " :visible.sync="visible2"
			:width="'66vw'">
			<div v-if="$store.state.identity != 3" :slot="'title'" class="pop-title">{{$t('topupdetails')}}</div>
			<div v-if="$store.state.identity == 3" :slot="'title'" class="pop-title">{{$t('revenueDetail')}}</div>
			<!-- <div style="width:556px;height:1px;background: ;"></div> -->
			<div :slot="'content'" class="pop-content-submit1">
				<el-table :data="tableData" height="250" :header-style="{ border: '1px solid red' }"
					style="width: 100%">
					<el-table-column align="center" prop="name" :label="$t('accounttitle')" width="180">
					</el-table-column>
					<el-table-column align="center" prop="changeNum" :label="$t('variationquantity')" width="180">
					</el-table-column>
					<el-table-column v-if="$store.state.identity != 3" align="center" prop="chargeMethod" :label="$t('rechargechannel')">
					</el-table-column>
					<el-table-column v-if="$store.state.identity == 3" align="center" prop="chargeMethod" :label="$t('revenueMethods')">
					</el-table-column>
					<el-table-column align="center" prop="balance" :label="$t('accountbalance')">
					</el-table-column>
					<el-table-column align="center" prop="data" :label="$t('time')">
					</el-table-column>
				</el-table>
			</div>
		</PopupCommon>

    <!-- 充值明细弹窗 -->
    <PopupCommon ref="popupRechargeDetail" customClassName="textarea-modal" title=" " :visible.sync="visible2" :width="'66vw'">
      <div :slot="'title'" class="pop-title">{{$t('topupdetails')}}</div>
      <div :slot="'content'" class="pop-content-submit1">
        <el-table :data="rechargeLogData" height="250" :header-style="{ border: '1px solid red' }" style="width: 100%">
          <el-table-column align="center" prop="payTypeTxt" :label="$t('rechargechannel')"></el-table-column>
          <el-table-column align="center" prop="amount" :label="$t('rechargemoney')"></el-table-column>
          <el-table-column align="center" prop="balance" :label="$t('rechargeshengyu')"></el-table-column>
          <el-table-column align="center" prop="time" :label="$t('integralshijiantxt')"></el-table-column>
        </el-table>
      </div>
    </PopupCommon>

    <!-- 积分明细弹窗 -->
    <PopupCommon ref="popupintegralDetail" customClassName="textarea-modal" title=" " :visible.sync="visible2" :width="'66vw'">
      <div :slot="'title'" class="pop-title">{{$t('integralDetail')}}</div>
      <div :slot="'content'" class="pop-content-submit1">
        <el-table :data="integralLogData" height="250" :header-style="{ border: '1px solid red' }" style="width: 100%">
          <el-table-column align="center" prop="way" :label="$t('integraltypetxt')"></el-table-column>
          <el-table-column align="center" prop="balance" :label="$t('integralshengyutxt')"></el-table-column>
          <el-table-column align="center" prop="time" :label="$t('integralshijiantxt')"></el-table-column>
        </el-table>
      </div>
    </PopupCommon>
	</div>
</template>

<script>
	import PopupCommon from "@/components/popup/PopupCommon.vue"
  import PayImmediatelyBottom from "@/components/content/PayImmediatelyBottom.vue"
  import {
    incomeLog,
    bankList,
    withdrawCash,
    recharge, getCheckOrder, moneyExchangeIntegral, integralLogGet, rechargeLogGet, myInfo
  } from "@/api/Mine"
  import {buyLockPrice} from "@/api/Home";
	export default {
		props: {},
		computed: {},
		components: {
      PayImmediatelyBottom,
			PopupCommon
		},
		data() {
			return {
        exintegral: '',
        payType: '',//支付方式 1支付宝 2微信
        day: '',//锁定时间
        payInfo: {
          codeImg: '',
          time: 10 * 60,
          money: '',
          isWechat: '1', //是否支持微信支付 0不支持 1支持
          isApliy: '1', //是否支持支付宝支付 0不支持 1支持
          isCorporateTransfer: '1', //是否支持对公转账 0不支持 1支持
          corporateName: this.$t("namecomponent") + this.$t("tranfercomponentinfo"),
          bankDeposit: this.$t("bankaccountinfo"),
          bankAccount: '21050110084600000051'

        },
				bankNameList: [], //银行列表
				visible1: false,
				visible2: false,
				visible: false,
				ruleForm: {
					money: 0
				},
        tixianruleForm: {
          bankCard: '',
          money: 0
        },
        exForm: {
          exmoney: 0,
        },
				rules: {
					money: [{
						message: this.$t("withdrawalamountnonull"),
						trigger: 'blur'
					}],
				},
        txrules: {
          bankCard: [{
            message: this.$t("changebankcard"),
            trigger: 'blur'
          }],
          money: [{
            message: this.$t("withdrawalamountnonull"),
            trigger: 'blur'
          }],
        },
        exrules: {
          exmoney: [{
            message: this.$t("changebankcard"),
            trigger: 'blur'
          }],
        },
				popTitle: '', //弹窗标题
				moneyLabel: '', //输入金额label
				moneyPlaceholder: '', //输入金额placeholder
				type: 1, // 1充值 2提现
				myInfo: {},
				tableData: [],
        integralLogData: [],
        rechargeLogData: [],
				// tableData: [{
				// 	name: 'Shawn west',
				// 	changeNum: '100',
				// 	chargeMethod: '支付宝',
				// 	balance: '100',
				// 	data: '2022-22-24'
				// }]
			}
		},
		created() {
			this.myInfo = JSON.parse(localStorage.getItem('myInfo'))
      this.$store.state.myInfo = this.myInfo
			this.bankList(); //银行列表
			this.incomeLog(); //收支明细
		},
		methods: {

      // 更新用户信息
      MyInfo(){
        myInfo({}).then((res) => {
          localStorage.setItem('myInfo', JSON.stringify(res.data))
        }).catch((err) => {});
      },

      // ==================================== 积分 ==========================================
      //打开兑换积分弹窗
      exchangePop(){
        this.$refs.popupExchange.open()
      },
      // 兑换金额实时变化
      inputduihuan(e){
        this.exintegral = e*10
      },
      // 兑换积分接口
      finishduihuanjifen(){
        let data = {};
        data.money = this.exForm.exmoney
        data.integral = this.exintegral
        if(data.money > this.myInfo.wallet){
          this.$message({type: "warning",message: '账户余额不足，请充值'});
          return
        }
        moneyExchangeIntegral(data).then((res) => {
          if (res.status === 1) {
            // 更新用户信息
            this.MyInfo();
            this.$refs.popupExchange.close()
            const loading = this.$loading({
              lock: true,
              text: '正在努力兑换中...',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            setTimeout(() => {
              loading.close();
              this.$router.go(0) && location.reload();
            }, 1000);
            // this.exForm.exmoney = ''
            // this.$router.push("/personalCenter/myWallet/accountBalance");
            // this.myInfo.integral = res.data.integral
            // this.myInfo.wallet = res.data.wallet
          }
        }).catch((err) => {});
      },

      // ============================================ 充值 =============================================
      // 充值金额实时变化
      inputaibian(e){
        this.payInfo.money = e
      },
      // 微信支付
      wechatPay(data){
        if(this.ruleForm.money == 0){
          this.$message({type: "warning",message: '请输入金额'});
          return
        }
        console.log(data,'wechatPay')
        this.payType = data
        if(this.payType != ''){
          this.getPayCode(this.ruleForm.money,this.payType)
        }
      },
      // 支付宝支付
      alipayPay(data){
        if(this.ruleForm.money == 0){
          this.$message({type: "warning",message: '请输入金额'});
          return
        }
        console.log(data,'alipayPay')
        this.payType = data
        if(this.payType != ''){
          this.getPayCode(this.ruleForm.money,this.payType)
        }
      },
      //获取支付二维码
      getPayCode(money, payType) {
        let params = {};
        params.money = money;
        params.payType = payType;

        recharge(params).then((res) => {
          if (res.status == 1) {
            this.payInfo.codeImg = res.data.qr_code
            this.order_id = res.data.order_id
          } else {
            this.$message({type: "error", message: res.msg});
          }
        });
      },
      // 完成支付
      demoFinishPay(){
        let data = {};
        data.order_id = this.order_id
        getCheckOrder(data).then((res) => {
          if (res.status === 1) {
            // 更新用户信息
            this.MyInfo();
            this.$refs.popupRecharge.close()
            const loading = this.$loading({
              lock: true,
              text: '正在努力查询中...',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            setTimeout(() => {
              loading.close();
              this.$router.go(0) && location.reload();
            }, 1000);
            // this.ruleForm.bankCard = ''
            // this.ruleForm.money = ''
            // this.payInfo.codeImg = ''
          }
        }).catch((err) => {
          // console.log(err, '获取banner失败');
        });
      },

			// 打开充值弹窗
			recharge(type) {
				// type 1充值 2提现
				this.type = type
				if (type == 1) {
          this.$refs.popupRecharge.open()
          this.popTitle = this.$t('recharge')
					this.moneyLabel = this.$t('rechargemoney')
					this.moneyPlaceholder = this.$t('inputrechargemoney')
					// this.rules.money.message = ''
				} else if (type == 2) {
          this.$refs.popupWithdraw.open()
          this.popTitle = this.$t('withdraw')
					this.moneyLabel = this.$t('withdraw')
					this.moneyPlaceholder = this.$t('withdrawtitle')
					// this.rules.money.message = '可提现金额200.00'
				}
			},
			// 获取银行列表
			bankList() {
				bankList({}).then((res) => {
						console.log(res, '获取银行列表');
						this.bankNameList = res.data
						// localStorage.setItem('myInfo', JSON.stringify(this.infoObj))
					})
					.catch((err) => {
						console.log(err, '我的信息失败');
					});
			},

      // =============================== 明细 =======================================

      // 积分明细
      integralDetail() {
        integralLogGet({}).then((res) => {
          if (res.status === 1) {
            this.integralLogData = res.data
          }
          this.$refs.popupintegralDetail.open()
        }).catch((err) => {
          console.log(err);
        });
      },
			// 充值明细
			chargeDetail() {
        rechargeLogGet({}).then((res) => {
          if (res.status === 1) {
            this.rechargeLogData = res.data
          }
          this.$refs.popupRechargeDetail.open()
        }).catch((err) => {
          console.log(err);
        });
				// this.$refs.popupChargeDetail.open()
			},
      // 收支明细
      incomeLog() {
        incomeLog({}).then((res) => {
          // console.log(res, '我的信息成功');
          if (res.status == 1) {
            this.tableData = res.data.list
          }
        }).catch((err) => {
              // console.log(err, '我的信息失败');
        });
      },
      // ======================================= 提现 ===================================================
      //提现 - 选择银行卡实时
      selectChange(e) {
        console.log(e, 'eeeeeeeeee')
        this.tixianruleForm.bankCard = e
      },
      //提现 - 取消
      cancelRecharge() {
        this.$refs.popupWithdraw.close()
        this.tixianruleForm.bankCard = ''
        this.tixianruleForm.money = ''
      },
      //提现 - 确认
      confirmRecharge() {
        let params = {};
        params.money = this.tixianruleForm.money;
        params.bank_id = this.tixianruleForm.bankCard
        if(this.tixianruleForm.bankCard == ''){
          this.$message({type: "warning",message: '请选择银行卡'});
          return
        }
        withdrawCash(params).then((res) => {
          if (res.status == 1) {
            this.$message({
              type: "success",
              message: res.msg,
            });
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
      },
      closePop() {
        this.$refs.popupSubmit.close()
      },
		}
	}
</script>

<style lang="less" scoped>
	* {
		::-webkit-scrollbar {
			/*隐藏滚轮*/
			display: none;
		}
	}

	::v-deep .el-input__inner {
		width: 402px;
		background: @recommendMusicianListBGColor;
		border: none;
		color: @uploadFormTextColor;

		&:hover {
			border: 1px solid @popupCancelTextColor;
		}
	}

	::v-deep .el-form-item__label {
		color: @recommendMusicianListTextColor;
	}

	.box {
		display: flex;
		justify-content: space-between;
		flex: 1 auto;
		color: #fff;
		// margin-left: 28px;
		width: 100%;
		height: 201px;
		// background: linear-gradient(134deg, #00BEA5 0%, #06D5BA 100%);
		background-image: url(../../../assets/images/personalCenter/account-balance.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		border-radius: 8px;
		// align-items: center;

		.left {
			display: flex;
			align-items: center;
			justify-content: space-around;
			width: 60%;

			.total-revenue {
				display: flex;
				flex-direction: column;
				align-items: center;
				.title{
					text-align: center;
          margin-bottom: 10px;
				}
				.num {
					font-size: 48px;
					font-family: Roadgeek2005Engschrift;
					color: #FFFFFF;
				}
			}
		}

		.right {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			margin-right: 24px;
			width: 40%;

			.top {
				display: flex;
				align-items: center;
				justify-content: flex-end;

				.detail {
					margin-right: 8px;
					cursor: pointer;
				}
			}

			.bottom {
				display: flex;
				justify-content: flex-end;

				.withdrawal {
					// width: 122px;
					padding: 0 10px;
					height: 36px;
					background: rgba(224, 249, 246, 0.4000);
					border-radius: 6px;
					font-family: Roadgeek2005Engschrift;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-right: 16px;
					cursor: pointer;
          margin-left: 15px;
				}

				.recharge {
					// width: 122px;
					padding: 0 10px;
					height: 36px;
					background: #00BCA4;
					border-radius: 6px;
					font-family: Roadgeek2005Engschrift;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
          margin-left: 15px;
				}
			}
		}

		// 充值弹窗
		.pop-title {
			height: 68px;
			display: flex;
			align-items: center;
			border-bottom: 1px solid @demoitemtabsBGColor;
			padding: 0 25px;
			color: @recommendMusicianListTextColor;
		}

		.pop-content {
			// max-height: 86px;
			padding: 24px;
			border-bottom: 1px solid @demoitemtabsBGColor;
			padding: 25px;
			color: @recommendMusicianListTextColor;
			line-height: 22px;

			.money-desc {
				font-size: 12px;
				font-weight: 400;
				color: #00BEA5;
			}
		}

		.pop-footer {
			display: flex;
			align-items: center;
			height: 68px;
			justify-content: center;

			.cancel {
				width: 102px;
				height: 36px;
				border-radius: 4px;
				border: 1px solid @popupCancelBorderColor;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				color: @popupCancelTextColor;
				margin-right: 44px;
				background: @popupCancelBgColor;
				cursor: pointer;
			}

			.confirm {
				width: 102px;
				height: 36px;
				background: #3278FF;
				border-radius: 4px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				color: #FFFFFF;
				cursor: pointer;
			}
		}

		// 提交成功弹窗
		.pop-content-submit {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 24px;
			border-bottom: 1px solid @demoitemtabsBGColor;

			.title {
				padding-top: 24px;
				font-size: 20px;
				font-weight: 500;
				// color: #FFFFFF;
				color: @recommendMusicianListTextColor;
				margin-bottom: 12px;
			}

			.desc {
				font-size: 14px;
				font-weight: 500;
				color: rgba(255, 255, 255, 0.6);
			}
		}

		.pop-footer-submit {
			display: flex;
			align-items: center;
			height: 68px;
			justify-content: center;

			.cancel {
				width: 102px;
				height: 36px;
				border-radius: 4px;
				border: 1px solid @popupCancelBorderColor;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				color: @popupCancelTextColor;
				// margin-right: 44px;
				background: @popupCancelBgColor;
			}
		}

		// 充值明细弹窗
		.pop-content-submit1 {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 24px;
			border-bottom: 1px solid @demoitemtabsBGColor;
			max-height: 40vh;

			::v-deep .el-table {
				background: @popupBackgroundColor;
				border-color: @popupCommonBorderColor;
				// border: 1px solid @popupBackgroundColor;
			}

			::v-deep .el-table__row {
				background: @popupBackgroundColor;
			}

			::v-deep .el-table th.el-table__cell {
				background: @popupBackgroundColor;

			}

			::v-deep .el-table__header-wrapper {
				// border-color: @popupCommonBorderColor;
				// display: flex;
				// justify-content: center;
				// background-color: red;
			}

			// ::v-deep has-gutter{

			// }


			::v-deep .el-table--border .el-table__cell {
				// border-right: none;
				// 	&:hover{
				// 		background-color: red;
				// 	}
			}

			::v-deep .el-table td.el-table__cell {
				color: @recommendMusicianListTextColor;
				border-color: @popupCommonBorderColor;
			}

			::v-deep .el-table__body-wrapper {
				// display: flex;
				// justify-content: center;
				border-color: @popupCommonBorderColor;
			}

			::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td {

				background-color: @tableHoverBgColor  !important;

			}

			// el-table--enable-row-transition
			// ::v-deep tr:hover{
			// 	background-color: red;
			// }
			// ::v-deep .el-table__body:hover{
			// 	background-color: red;
			// }
		}

	}
</style>
